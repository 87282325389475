@import "mixins"
.footer
    background: var(--footer_bg)
    display: flex
    flex-direction: column
    padding: 18px 8px calc(var(--menuHeight) + 20px)

.logo
    @include flex
    margin-bottom: 26px

.item
    border-bottom: 1px solid #09161E
    @include bold
    font-size: 16px
    overflow: hidden
    > div
        background: var(--footer_bg)
        min-height: 40px
        color: rgb(var(--secondaryColor))
        @include flex
        text-transform: uppercase
        position: relative
        z-index: 1

        &.opened .arrow
            transform: rotate(180deg)
            opacity: 1

    > nav > *
        @include flex
        min-height: 24px
        color: #696C7B
        margin-bottom: 8px

.arrow
    width: 10px
    height: 5px
    background: var(--icon-arrow) no-repeat
    transform: rotate(0deg)
    transform-origin: 45% 50%
    transition: all 0.2s
    opacity: .6
    margin-left: auto
    min-width: 10px

.txt
    color: #828592
    font-size: 15px
    line-height: 24px
    padding: 8px 0

.socialLinks
    @include flex
    justify-content: center
    padding: 8px
    border-top: 1px solid #09161E
    border-bottom: 1px solid #09161E
    svg path
        fill: #E6E8EC

.footerSite
    margin-top: 20px
    margin-bottom: 6px
    color: var(--footer_versionColor)

.version
    color: var(--footer_versionColor)
